import { APP_LOADER } from '@/env'
import { StyledLoader, StyledLoaderIcon } from './styles'

const Loader = () => {
  return (
    <StyledLoader>
      <StyledLoaderIcon width="64" height="64" src={APP_LOADER} alt="Carregando..." />
    </StyledLoader>
  )
}

export default Loader
