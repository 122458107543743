import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type PersistedState<T> = [T, Dispatch<SetStateAction<T>>]

function usePersistedState<T>(defaultValue: T, key: string): PersistedState<T> {
  const [value, setValue] = useState<T>(() => {
    const persistedValue = window.sessionStorage.getItem(key)

    return persistedValue ? (JSON.parse(persistedValue) as T) : defaultValue
  })
  useEffect(() => {
    if (window) {
      window.sessionStorage.setItem(key, JSON.stringify(value))
    }
  }, [key, value])

  return [value, setValue]
}

export { usePersistedState }
