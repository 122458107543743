import { Avatar, IconButton, Toolbar } from '@mui/material'
import { Logo, ToolbarCenter, ToolbarLeft, ToolbarRight, AppBar } from './styles'
import { useMemo } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useAuth0 } from '@auth0/auth0-react'

interface Props {
  open: boolean
  setOpenMenu(value: boolean): void
}

export default function Header({ open, setOpenMenu }: Props) {
  const isSmall = useIsMobile()

  const className = useMemo(() => {
    const names: string[] = []

    return names.join(' ')
  }, [])

  const { user } = useAuth0()
  const getNickname = () =>
    useMemo(() => (user?.nickname || 'us').slice(0, 2).toUpperCase(), [user])
  return (
    <AppBar position="fixed" open={open} elevation={0}>
      <Toolbar>
        {isSmall && (
          <>
            <ToolbarLeft className={className}>
              <IconButton
                size="large"
                edge="start"
                color="primary"
                sx={{ bgcolor: open ? 'rgba(0, 106, 235, 0.15)' : 'unset' }}
                aria-label="menu"
                onClick={() => setOpenMenu(!open)}
              >
                <MenuIcon />
              </IconButton>
            </ToolbarLeft>

            <ToolbarCenter className={className}>
              <Logo
                onClick={(event) => {
                  event.preventDefault()
                }}
              >
                <img height="24px" src="/assets/img/logo.svg" alt="Logo" />
              </Logo>
            </ToolbarCenter>
          </>
        )}
        <ToolbarRight sx={{ width: isSmall ? '70px' : '100%' }} className={className}>
          <Avatar alt="User" src={user?.picture}>
            {getNickname()}
          </Avatar>
        </ToolbarRight>
      </Toolbar>
    </AppBar>
  )
}
