import { datadogRum } from '@datadog/browser-rum'
import {
  ENVIRONMENT,
  DATADOG_ENABLED,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  ENDPOINT
} from './env'

if (DATADOG_ENABLED) {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID as string,
    clientToken: DATADOG_CLIENT_TOKEN as string,
    site: 'datadoghq.com',
    service: 'portal-manager',
    env: ENVIRONMENT.toLowerCase().substring(0, 3),
    sessionSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [(url) => url.startsWith(ENDPOINT)],
    useSecureSessionCookie: true,
    trackResources: true,
    trackLongTasks: true,
    enableExperimentalFeatures: ['clickmap']
  })
}
