import { styled } from '@mui/material/styles'

export const DrawerFooter = styled('div')`
  display: flex;
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: center;
  gap: 8px;
  padding: 16px 0;
`
