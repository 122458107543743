import { Navigate, Route, Routes } from 'react-router-dom'
import { AdvanceBorderoProvider } from './context/AdvanceBordero'
import { lazy, Suspense } from 'react'
import FullSizeLoader from './components/FullSizeLoader'
const OperationHistory = lazy(() => import('./pages/Vendor/OperationsHistory'))
const Logout = lazy(() => import('./pages/Logout'))
const AccountSettings = lazy(() => import('./pages/AccountSettings'))
const FollowDispatchDetails = lazy(() => import('./pages/Vendor/FollowDispatchDetails'))
const BatchUpload = lazy(() => import('./pages/Vendor/BatchUpload'))
const AdvanceBordero = lazy(() => import('./pages/Vendor/AdvanceBordero'))
const BorderoDetails = lazy(() => import('./pages/Vendor/BorderoDetails'))
const Taxes = lazy(() => import('./pages/Funder/Taxes'))
const VendorBatchUpload = lazy(() => import('./pages/Funder/VendorBatchUpload'))
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'))
const Router = () => {
  return (
    <Suspense fallback={<FullSizeLoader withSidebar />}>
      <Routes>
        <Route
          path="/advance-bordero"
          Component={() => (
            <AdvanceBorderoProvider>
              <AdvanceBordero />
            </AdvanceBorderoProvider>
          )}
        />
        <Route path="/advance-bordero/:uuid" element={<BorderoDetails />} />
        <Route path="/document-batch-upload" element={<BatchUpload />} />
        <Route path="/follow-dispatch/:uuid" element={<FollowDispatchDetails />} />
        <Route path="/follow-dispatch" element={<OperationHistory />} />
        <Route path="/taxes" element={<Taxes />} />
        <Route path="/vendor-batch-upload" element={<VendorBatchUpload />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/" element={<Navigate to="/follow-dispatch" replace />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  )
}

export default Router
