import { textPrimaryColor } from '@/helpers/styles'
import { Box, Typography } from '@mui/material'
import IconError from '@mui/icons-material/Error'
import IconWarning from '@mui/icons-material/Warning'

type Props = {
  type: 'error' | 'empty'
  title?: string
  message?: string
}

const AlertMessage = ({ type, title, message }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10vh',
        textAlign: 'center'
      }}
    >
      {type === 'empty' && (
        <IconError color="disabled" sx={{ marginBottom: '24px', height: '50px', width: '50px' }} />
      )}
      {type === 'error' && (
        <IconWarning
          color="disabled"
          sx={{ marginBottom: '24px', height: '50px', width: '50px' }}
        />
      )}
      <Typography variant="h1" color={textPrimaryColor} sx={{ marginBottom: '8px' }}>
        {title || 'Nenhum registro encontrado'}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {message || 'No momento, não há dados disponíveis nesta seção.'}
      </Typography>
    </Box>
  )
}

export default AlertMessage
