import React from 'react'
import Layout from './components/Layout'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useAuthContext } from './context/AuthContext'
import Router from './router'
import FullSizeLoader from './components/FullSizeLoader'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from './pages/ErrorPage'
import { useLocation } from 'react-router-dom'

const queryClient = new QueryClient()

function App() {
  const { isAuthenticated, isLoading } = useAuthContext()
  if (isLoading || !isAuthenticated) {
    return (
      <div>
        <FullSizeLoader />
      </div>
    )
  }

  const location = useLocation()

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <ErrorBoundary key={location.pathname} fallback={<ErrorPage />}>
            <Router />
          </ErrorBoundary>
        </Layout>
      </QueryClientProvider>
    </React.StrictMode>
  )
}

export default App
