import { alpha, Box } from '@mui/material'
import Loader from '../Loader'
import { SIDEBAR_WIDTH } from '@/helpers/styles'
import { useIsMobile } from '@/hooks/useIsMobile'

type Props = {
  withSidebar?: boolean
}

const FullSizeLoader = ({ withSidebar }: Props) => {
  const isMobile = useIsMobile()
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: alpha('#ffffff', 0.8),
        left: withSidebar && !isMobile ? `${SIDEBAR_WIDTH}px` : 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: ' center',
        justifyContent: 'center'
      }}
    >
      <Loader />
    </Box>
  )
}

export default FullSizeLoader
