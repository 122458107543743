import { SIDEBAR_WIDTH } from '@/helpers/styles'
import { css, styled, AppBar as MuiAppBar, AppBarProps as MuiAppBarProps } from '@mui/material'
import Box from '@mui/material/Box'

export const Logo = styled('a')`
  cursor: pointer;
  text-decoration: none;
`

export const ToolbarCenter = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(0, 2)};

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      justify-content: left;

      &.no-companies {
        padding-left: 0;
      }
    }
  `}
`

export const ToolbarLeft = styled(Box)`
  width: 70px;
  display: flex;
  justify-content: left;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: initial;
    }
  `}
`

export const ToolbarRight = styled(Box)`
  width: 70px;
  display: flex;
  justify-content: right;
`

const drawerWidth = SIDEBAR_WIDTH

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: '#FAFAFA',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))
