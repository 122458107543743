interface WindowAppConfig {
  tenant: {
    name: string
    logo: string
    logo_square: string
    favicon: string
    loader: string
    primary_color: string
    secondary_color: string
    application: {
      url: string
      client_id: string
      audience: string
    }
  }
}

declare global {
  interface Window {
    APP_CONFIG: WindowAppConfig
  }
}

// Tenant
export const APP_NAME = window.APP_CONFIG.tenant.name
export const APP_LOGO = window.APP_CONFIG.tenant.logo
export const APP_LOGO_SQUARE = window.APP_CONFIG.tenant.logo_square
export const APP_FAVICON = window.APP_CONFIG.tenant.favicon
export const APP_LOADER = window.APP_CONFIG.tenant.loader

// Colors
export const PRIMARY_COLOR = import.meta.env.VITE_PRIMARY_COLOR
export const SECONDARY_COLOR = import.meta.env.VITE_SECONDARY_COLOR

// Auth0
export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE

// Environment
export const ENDPOINT = import.meta.env.VITE_ENDPOINT_API
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT
export const DATADOG_ENABLED = import.meta.env.VITE_DATADOG_ENABLED === 'true'
export const DATADOG_APPLICATION_ID = import.meta.env.VITE_DATADOG_APPLICATION_ID
export const DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN
