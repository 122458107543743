import { SIDEBAR_WIDTH } from '@/helpers/styles'
import { styled } from '@mui/material'

export const Span = styled('span')`
  width: 100%;
  display: block;

  .subitens {
    display: none;
    opacity: 0;
    transition:
      opacity 246ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 164ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover .subitens {
    display: block;
    position: fixed;
    opacity: 1;
    transform: none;
    transition:
      opacity 246ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 164ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: 48px;
  }
`

export const drawerWidth = SIDEBAR_WIDTH

export const DrawerHeader = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 170px;
  color: lightblue;
  padding-bottom: 44px;
`
