import AlertMessage from '@/components/AlertMessage'

export default function ErrorPage() {
  return (
    <AlertMessage
      type="error"
      title="Ocorreu um problema!"
      message="Por favor, tente novamente mais tarde. Se o problema persistir, entre em contato com o suporte técnico."
    />
  )
}
