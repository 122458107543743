import { Typography } from '@mui/material'
import { DrawerFooter } from './styles'

export default function DrawerFooterComponent() {
  return (
    <DrawerFooter>
      <Typography variant="subtitle2" color="grey.300">
        Powered by
      </Typography>
      <img alt="LOGO" src="/assets/img/logogrey.svg" height="16px" />
    </DrawerFooter>
  )
}
