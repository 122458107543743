import { Box } from '@mui/material'
import { ContentContainer } from './styles'
import Header from '../Header'
import { useEffect, useState } from 'react'
import MenuDrawer from '../Menu'
import { useIsMobile } from '@/hooks/useIsMobile'

interface Props {
  children: JSX.Element
}

export default function Layout({ children }: Props) {
  const isSmall = useIsMobile()
  const [open, setOpen] = useState<boolean>(!isSmall)

  useEffect(() => {
    setOpen(!isSmall)
  }, [isSmall])

  return (
    <Box sx={{ display: 'flex' }}>
      <Header open={open} setOpenMenu={setOpen} />
      <MenuDrawer open={open} />

      <ContentContainer open={open}>{children}</ContentContainer>
    </Box>
  )
}
