import { styled } from '@mui/material'

export const StyledLoader = styled('div')`
  display: block;
`

export const StyledLoaderIcon = styled('img')`
  display: block;
  margin: 0 auto;
`
