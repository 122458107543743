import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'
import { MenuInterface } from './menuItems'

export default function MenuItem({
  text,
  icon,
  path,
  match
}: Pick<MenuInterface, 'text' | 'icon' | 'path' | 'match'>) {
  const navigate = useNavigate()
  const location = useLocation()
  const isMenuActive = !!matchRoutes(
    [{ path: match?.path }, { path: `${match?.path}/:id` }],
    location
  )

  return (
    <ListItemButton
      onClick={() => navigate(path!)}
      selected={isMenuActive}
      sx={{
        paddingLeft: icon ? '27px' : '51px',
        '&.Mui-selected': {
          background: (theme) => theme.palette.primary.main
        }
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          justifyContent: 'center',
          pr: 2,
          color: isMenuActive ? 'common.white' : 'grey.300'
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ color: isMenuActive ? 'common.white' : 'grey.800' }}>
        {text}
      </ListItemText>
    </ListItemButton>
  )
}
