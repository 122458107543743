import { Bordero } from '@/types/Bordero'
import { ReactNode, createContext, useContext, useState } from 'react'

export interface IAdvanceBorderoContext {
  borderosSelected: Bordero[]
  handleStartAdvance: (borderos: Bordero[]) => void
  handleClearAdvance: () => void
}

export const AdvanceBorderoContext = createContext<IAdvanceBorderoContext>({
  borderosSelected: [],
  handleStartAdvance: () => null,
  handleClearAdvance: () => null
})

type Props = {
  children: ReactNode
}

export const AdvanceBorderoProvider = ({ children }: Props) => {
  const [borderosSelected, setBorderosSelected] = useState<Bordero[]>([])

  const handleStartAdvance = (borderos: Bordero[]) => {
    setBorderosSelected(borderos)
  }

  const handleClearAdvance = () => {
    setBorderosSelected([])
  }

  return (
    <AdvanceBorderoContext.Provider
      value={{ borderosSelected, handleStartAdvance, handleClearAdvance }}
    >
      {children}
    </AdvanceBorderoContext.Provider>
  )
}

export const useAdvanceBorderoContext = () => {
  return useContext(AdvanceBorderoContext)
}
