import { ENDPOINT } from '@/env'
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: ENDPOINT,
  withCredentials: true
})

export const setAxiosInstanceAuthToken = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default axiosInstance
