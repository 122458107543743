import { ExpandMoreOutlined } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { matchPath, useLocation } from 'react-router-dom'
import MenuItem from './menuItem'
import { MenuInterface, SubmenuInterface } from './menuItems'
import { useAuthContext } from '@/context/AuthContext'
import { useIsMobile } from '@/hooks/useIsMobile'
import { textPrimaryColor } from '@/helpers/styles'

export interface MenuAccordionInterface {
  menu: MenuInterface
}

const validateAllPermissions = (userPermissions: string[], menu: MenuInterface): boolean => {
  const flatPermissions = menu.submenu.reduce<string[]>((acc, current) => {
    return [...acc, ...current.permissions]
  }, [])

  return userPermissions.some((permission) => flatPermissions.includes(permission))
}

const filterPermissions =
  (userPermissions: string[]) =>
  (c: SubmenuInterface): boolean => {
    return userPermissions.some((perm: string) => c.permissions?.includes(perm))
  }

export const MenuAccordion = ({ menu }: MenuAccordionInterface) => {
  const { permissions } = useAuthContext()
  const location = useLocation()

  const isSmall = useIsMobile()

  const openSelectedAccordion = menu.submenu.some((submenu) => {
    return matchPath(submenu.path, location.pathname)
  })

  if (!validateAllPermissions(permissions, menu)) {
    return <></>
  }

  return (
    <Accordion
      disableGutters
      defaultExpanded={!isSmall || openSelectedAccordion}
      sx={{ boxShadow: 'unset', ':before': { height: 0 } }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        sx={{
          padding: '0px 9px 0 26px'
        }}
      >
        <Grid container alignItems={'center'} sx={{ color: 'grey.300' }}>
          {menu.icon}
          <Typography
            color={textPrimaryColor}
            sx={{
              paddingLeft: '19px'
            }}
          >
            {menu.text}
          </Typography>
        </Grid>
      </AccordionSummary>

      <AccordionDetails sx={{ padding: 0, backgroundColor: '#fafafa' }}>
        {menu.submenu.filter(filterPermissions(permissions)).map((submenu) => (
          <MenuItem
            key={submenu.path}
            text={submenu.text}
            icon={submenu.icon}
            path={submenu.path}
            match={submenu.match}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
