import { styled } from '@mui/material'
import { drawerWidth } from '../Menu/styles'

export const ContentContainer = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  marginTop: 64,
  flexGrow: 1,
  padding: '24px 42px',
  width: '100%',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }),
  '@media (max-width: 599px)': {
    marginTop: 56,
    padding: '24px 16px'
  }
}))
